/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://yas353o6anajlgtxja7pscainu.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5owdjotrgbgvdbxvrx2mqvr6sq",
    "aws_cognito_identity_pool_id": "eu-west-1:cb12eddc-c577-42c0-a705-7e4f88ab0352",
    "aws_cognito_region": "eu-west-1",
    "oauth": {},
    "aws_mobile_analytics_app_id": "412034048e4c4f2abf41810baa71f236",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
